<template>
    <a-layout-content class="Question">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
                <router-link :to="{ path: '/products' }" class="nav-link">
                    Danh sách sản phẩm
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <a-layout :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">

        </a-layout>
    </a-layout-content>
</template>

<script>
    export default {
        name: "ProductDetails",
        created() {
            // eslint-disable-next-line no-console
            console.log(this.$route.params.productId);
        }
    }
</script>

<style scoped>

</style>